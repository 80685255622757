import {
	Stack,
	Box,
	Container,
	Typography,
	useTheme,
	useMediaQuery,
	Link,
	alpha,
} from '@mui/material'
import { motion } from 'framer-motion'
import Button from '../shared/Button'
import { socialLinks } from '../shared/data'
import './cubeStyles.css'

function LandingArea() {
	const theme = useTheme()
	const match1401pxDown = useMediaQuery('(max-width: 1400px)') // width <= 1400px
	const match1168pxDown = useMediaQuery('(max-width: 1167px)') // width <= 1167px
	const match721pxDown = useMediaQuery('(max-width: 720px') // width <= 720px
	const matchSmDown = useMediaQuery(theme.breakpoints.down('sm')) // width <= 599px

	const initial = {
		y: 300,
		opacity: 0,
	}
	const animate = {
		y: 0,
		opacity: 1,
	}

	return (
		<Container id='home' maxWidth='xl'>
			<Stack
				direction='row'
				sx={{
					height: '100vh',
					minHeight: '600px',
					overflow: 'hidden',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '100%',
						width: match1401pxDown ? (match1168pxDown ? '100%' : '60%') : '55%',
					}}
				>
					<Box>
						<Typography
							component={motion.p}
							initial={initial}
							animate={animate}
							transition={{
								delay: matchSmDown ? 0.8 : 1.2,
								duration: 0.3,
							}}
							variant='body1'
							color='primary'
							sx={{
								fontFamily: `'Source Code Pro', monospace`,
								marginBottom: '1rem',
							}}
						>
							Hi, my name is
						</Typography>

						<Typography
							sx={{
								fontWeight: '700',
							}}
							component={motion.h2}
							initial={initial}
							animate={animate}
							transition={{
								delay: matchSmDown ? 0.9 : 1.3,
								duration: 0.3,
							}}
							variant={match721pxDown ? (matchSmDown ? 'h4' : 'h3') : 'h2'}
						>
							Abhijeet Singh.
						</Typography>

						<Typography
							sx={{
								fontWeight: '700',
								marginBottom: '1rem',
							}}
							component={motion.h2}
							initial={initial}
							animate={animate}
							transition={{
								delay: matchSmDown ? 1 : 1.4,
								duration: 0.3,
							}}
							variant={match721pxDown ? (matchSmDown ? 'h4' : 'h3') : 'h2'}
							color={alpha(theme.palette.text.secondary, 0.6)}
						>
							I build things for the web.
						</Typography>

						<Typography
							component={motion.p}
							initial={initial}
							animate={animate}
							transition={{
								delay: matchSmDown ? 1.1 : 1.5,
								duration: 0.3,
							}}
							variant='body1'
							color={theme.palette.text.secondary}
							sx={{
								marginBottom: '2rem',
								maxWidth: '560px',
							}}
						>
							I'm a Frontend Developer. I have expertise in JavaScript, React,
							Redux-Toolkit, Express, Node.js, and MongoDB with best practices. Check
							out my projects or get in touch!
						</Typography>

						<Stack
							component={motion.div}
							initial={initial}
							animate={animate}
							transition={{
								delay: matchSmDown ? 1.2 : 1.6,
								duration: 0.3,
							}}
							direction='row'
							spacing={matchSmDown ? 2 : 2.5}
							alignItems='center'
						>
							<Link href='#projects' underline='none'>
								<Button>
									<Typography
										component='span'
										variant='body1'
										sx={{
											fontFamily: `'Source Code Pro', monospace`,
										}}
									>
										My Projects
									</Typography>
								</Button>
							</Link>
							{socialLinks.map(link => {
								return (
									<Typography
										key={link.id}
										color={theme.palette.text.secondary}
										component='a'
										href={link.link}
										target={link.id !== 1 ? '_blank' : undefined}
										variant='h6'
										sx={{
											transition: 'all 0.3s ease-in-out',
											'&:hover': {
												color: theme.palette.primary.main,
												transform: `translateY(-0.25rem) scale(1.1)`,
											},
										}}
									>
										{link.icon}
									</Typography>
								)
							})}
						</Stack>
					</Box>
				</Box>
				{!match1168pxDown && (
					<Box
						component={motion.div}
						initial={{
							x: '100vw',
						}}
						animate={{
							x: 0,
						}}
						transition={{
							delay: 1.2,
						}}
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
							width: match1401pxDown ? '40%' : '45%',
						}}
					>
						<div className='cube'>
							<div className='top'></div>
							<div>
								<span
									style={{
										'--i': 0,
									}}
								></span>
								<span
									style={{
										'--i': 1,
									}}
								></span>
								<span
									style={{
										'--i': 2,
									}}
								></span>
								<span
									style={{
										'--i': 3,
									}}
								></span>
							</div>
						</div>
					</Box>
				)}
			</Stack>
		</Container>
	)
}

export default LandingArea
