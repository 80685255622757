import { Backdrop, Drawer, } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { useEffect, useRef, } from 'react'
import ReactDOM from 'react-dom'
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks,
} from 'body-scroll-lock'

function Sidebar(props) {
	const { open, handleClose } = props
	const theme = useTheme()
	const targetRef = useRef()

	useEffect(() => {
		if (open) disableBodyScroll(targetRef.current)
		else enableBodyScroll(targetRef.current)

		return () => {
			clearAllBodyScrollLocks()
		}
	}, [open])

	return (
		<>
			{ReactDOM.createPortal(
				<Backdrop
					open={open}
					onClick={handleClose}
					sx={{
						top: 0,
						left: 0,
						right: 0,
						height: '100vh',
						position: 'fixed',
						backgroundColor: 'transparent',
						backdropFilter: 'blur(5px) brightness(0.7)',
						transition: theme.transitions.create(
							['background-color', 'transform'],
							{
								duration: theme.transitions.duration.standard,
							}
						),
					}}
				></Backdrop>,
				document.getElementById('backdrop')
			)}
			<Drawer
				ref={targetRef}
				variant='persistent'
				anchor='right'
				open={open}
				onClose={handleClose}
				PaperProps={{
					sx: {
						height: '100vh',
						width: '70vw',
						border: 'none',
						display: 'grid',
						placeContent: 'center',
						boxShadow: '10px 0px 30px 0px rgba(0,0,0,0.75)',
					},
				}}
			>
				{props.children}
			</Drawer>
		</>
	)
}

export default Sidebar
