import React, { useState, useEffect} from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import './loading.css'
import { Box } from '@mui/material'

function Loading() {
	const [showLogo, setShowLogo] = useState(true)

	useEffect(() => {
		const id = setInterval(() => {
			setShowLogo(false)
		}, 2700)

		return () => {
			clearInterval(id)
		}
	}, [])

	const loadingVariants = {
		initial: {
			scale: 1.5,
		},
		animate: {
			scale: 0,
			transition: {
				duration: 2,
			},
		},
	}

	const logoVariants = {
		initial: {
			opacity: 0,
			scale: 0.2,
		},
		animate: {
			opacity: 1,
			scale: 1.5,
			transition: {
				delay: 1.7,
			},
		},
		exit: {
			opacity: 0,
			transition: { duration: 0.3 },
		},
	}

	return (
		<Box className='loading-page' bgcolor='background.default'>
			<motion.div
				variants={loadingVariants}
				initial='initial'
				animate='animate'
				className='loader'
			>
				<div className='inner one'></div>
				<div className='inner two'></div>
				<div className='inner three'></div>
			</motion.div>

			<AnimatePresence>
				{showLogo && <motion.div
						variants={logoVariants}
						initial='initial'
						animate='animate'
						exit='exit'
						className='logo'
					>
						<svg
							width='483'
							height='483'
							viewBox='0 0 483 483'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M241.5 403C330.694 403 403 330.694 403 241.5C403 152.306 330.694 80 241.5 80C152.306 80 80 152.306 80 241.5C80 330.694 152.306 403 241.5 403ZM241.5 483C303.594 483 360.215 459.565 403 421.058V443V444H403.012C403.543 465.629 421.243 483 443 483C464.757 483 482.457 465.629 482.988 444H483V443V246H482.959C482.986 244.503 483 243.003 483 241.5C483 108.123 374.877 0 241.5 0C108.123 0 0 108.123 0 241.5C0 374.877 108.123 483 241.5 483Z'
								fill='#39FF14'
							/>
						</svg>
					</motion.div>
				}
			</AnimatePresence>
		</Box>
	)
}

export default Loading
