import * as React from 'react'
import { Box } from '@mui/material'
import Navbar from './components/navbar/Navbar'
import LandingArea from './components/LandingArea/LandingArea'
const About = React.lazy(() => import('./components/About/About'))
const Projects = React.lazy(() => import('./components/Projects/Projects'))
const Contact = React.lazy(() => import('./components/Contact/Contact'))
const Footer = React.lazy(() => import('./components/Footer/Footer'))

function App() {
	return (
		<Box>
			<Navbar />
			<LandingArea />
			<React.Suspense fallback={<div></div>}>
				<About />
			</React.Suspense>
			<React.Suspense fallback={<div></div>}>
				<Projects />
			</React.Suspense>
			<React.Suspense fallback={<div></div>}>
				<Contact />
			</React.Suspense>
			<React.Suspense fallback={<div></div>}>
				<Footer />
			</React.Suspense>
		</Box>
	)
}

export default App
