import React from 'react'
import { motion } from 'framer-motion'
import './hamburger.css'

function Hamburger(props) {
	return (
		<motion.label
			initial={{
				y: -200,
			}}
			animate={{
				y: 0,
			}}
			transition={{
				delay: 0.5,
				duration: 0.3,
			}}
			className='menu_container'
			htmlFor='check'
		>
			<input
				type='checkbox'
				id='check'
				checked={props.open}
				onChange={props.onChange}
			/>
			<span></span>
			<span></span>
			<span></span>
		</motion.label>
	)
}

export default Hamburger
