import React from 'react'
import { Button as Btn, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

function Button(props) {
	const theme = useTheme()
	const matchSmUp = useMediaQuery(theme.breakpoints.up('sm'))

	return (
		<Btn
			variant='outlined'
			size='large'
			sx={{
				padding: matchSmUp ? '12px 36px' : '10px 30px',
				border: `1px ${theme.palette.primary.main} solid`,
				...props.sx_,
			}}
			{...props}
		>
			{props.children}
		</Btn>
	)
}

export default Button
