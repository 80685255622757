import { createTheme } from '@mui/material/styles'

const mode = 'dark'

const theme = createTheme({
	palette: {
		mode: mode,
		primary: {
			main: mode === 'light' ? '#0071d8' : '#39FF14',
		},
		background: {
			transparent: 'rgba(18, 18, 18, 0.85)',
			default: mode === 'light' ? '#eef0f4' : '#121212',
			paper: mode === 'light' ? '#fff' : '#202020',
		},
	},
	shape: {
		borderRadius: 16,
	},
	shadows: Array(25).fill('none'),
	typography: {
		button: {
			textTransform: 'none',
		},
	},
})

export default theme
