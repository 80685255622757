const links = [
	{
		label: 'Home',
		href: '#home',
	},
	{
		label: 'About',
		href: '#about',
	},
	// {
	// 	label: 'Experience',
	// 	href: '#experience'
	// },
	{
		label: 'Projects',
		href: '#projects',
	},
	{
		label: 'Contact',
		href: '#contact',
	},
]

export default links
