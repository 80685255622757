import { useState } from 'react'
import {
	AppBar,
	Toolbar,
	Container,
	Box,
	Tabs,
	Tab,
	Link,
	useScrollTrigger,
	Slide,
	useMediaQuery,
	Stack,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import logo from './../../assets/img/logo.svg'
import { useEffect } from 'react'
import Hamburger from './Hamburger'
import Sidebar from './Sidebar'
import navLinks from '../shared/navLinks'
import { motion } from 'framer-motion'

const Navbar = () => {
	const [value, setValue] = useState(0)
	const [down, setDown] = useState(false)
	const matches = useMediaQuery(theme => theme.breakpoints.up('sm'))
	const [menuOpen, setMenuOpen] = useState(false)
	const theme = useTheme()

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	useEffect(() => {
		const changeNavbarColor = () => {
			if (window.scrollY >= 80) {
				setDown(true)
			} else {
				setDown(false)
			}
		}

		window.addEventListener('scroll', changeNavbarColor)

		return () => {
			window.removeEventListener('scroll', changeNavbarColor)
		}
	}, [])

	useEffect(() => {
		if (matches) {
			setMenuOpen(false)
		}
	}, [matches])

	// framer motion variants
	const logoVariants = {
		initial: {
			y: -200,
			opacity: 0,
		},
		animate: {
			y: 0,
			opacity: 1,

			transition: {
				delay: 0.2,
				duration: 0.3,
			},
		},
	}

	return (
		<HideOnScroll>
			<AppBar
				position='fixed'
				sx={{
					backgroundImage: 'none',
					boxShadow: down ? '0px -10px 30px 0px rgba(0,0,0,0.75)' : 'none',
					backgroundColor: down
						? menuOpen
							? 'transparent'
							: 'background.transparent'
						: 'transparent',
					backdropFilter: down ? 'blur(8px)' : 'blur(0)',
					transition: theme.transitions.create(['all', 'transform'], {
						duration: theme.transitions.duration.standard,
					}),
				}}
			>
				<Container maxWidth='xl'>
					<Toolbar
						sx={{
							marginTop: down ? '0' : '1rem',
							transition: theme.transitions.create(['all', 'transform'], {
								duration: theme.transitions.duration.standard,
							}),
						}}
					>
						<Box
							onClick={() => setMenuOpen(false)}
							sx={{
								position: 'fixed',
								top: 0,
								bottom: 0,
								left: 0,
								right: 0,
							}}
						></Box>
						<Link
							href='/'
							sx={{
								zIndex: 1,
							}}
						>
							<Box
								component={motion.img}
								variants={logoVariants}
								initial='initial'
								animate='animate'
								src={logo}
								alt='Abhijeet Singh'
								sx={{
									width: 26,
									height: 26,
								}}
							/>
						</Link>

						{matches && (
							<Tabs
								value={value}
								onChange={handleChange}
								indicatorColor='none'
								sx={{
									marginLeft: 'auto',
								}}
							>
								{navLinks.map((item, key) => {
									return (
										<NavTab
											i={key}
											key={key}
											label={
												<Box
													sx={{
														fontFamily: 'Source Code Pro, monospace',
														textTransform: 'none',
														'&: hover': {
															color: theme.palette.primary.main
														}
													}}
												>
													<Box component='span' color='primary.dark'>
														{'< '}
													</Box>
													{item.label}
													<Box component='span' color='primary.dark'>
														{' />'}
													</Box>
												</Box>
											}
											href={item.href}
										/>
									)
								})}
							</Tabs>
						)}

						{!matches && (
							<Box
								sx={{
									marginLeft: 'auto',
								}}
							>
								<Hamburger
									open={menuOpen}
									onChange={() => {
										setMenuOpen(prev => !prev)
									}}
								/>
								<Sidebar open={menuOpen} handleClose={() => setMenuOpen(false)}>
									<Stack direction='column' alignItems={'center'}>
										{navLinks.map((item, key) => {
											return (
												<NavTab
													onClick={() => setMenuOpen(false)}
													key={key}
													label={
														<Box
															sx={{
																fontFamily: 'Source Code Pro, monospace',
																textTransform: 'none',
															}}
														>
															<Box component='span' color='primary.dark'>
																{'< '}
															</Box>
															{item.label}
															<Box component='span' color='primary.dark'>
																{' />'}
															</Box>
														</Box>
													}
													href={item.href}
												/>
											)
										})}
									</Stack>
								</Sidebar>
							</Box>
						)}
					</Toolbar>
				</Container>
			</AppBar>
		</HideOnScroll>
	)
}

function NavTab(props) {
	return (
		<Tab
			component={motion.a}
			initial={{
				y: -200,
				opacity: 0,
			}}
			animate={{
				y: 0,
				opacity: 1,
			}}
			transition={{
				delay: ((props.i + 1) * 0.1) + 0.5,
				duration: 0.3,
			}}
			disableRipple
			{...props}
		/>
	)
}

function HideOnScroll(props) {
	const trigger = useScrollTrigger()

	return (
		<Slide appear={false} direction='down' in={!trigger}>
			{props.children}
		</Slide>
	)
}

export default Navbar
