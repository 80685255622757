import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './theme'
import Loading from './components/Loading/Loading'
import { Toaster } from 'react-hot-toast'

const root = ReactDOM.createRoot(document.getElementById('root'))

function Main() {
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		AOS.init({
			once: true,
		})
		const id = setTimeout(() => {
			setIsLoading(false)
		}, 3000)

		return () => {
			clearTimeout(id)
		}
	}, [])
	return <>{isLoading ? <Loading /> : <App />}</>
}

root.render(
	<>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Main />
		</ThemeProvider>

		<Toaster
			toastOptions={{
				className: '',
				style: {
					background: '#333',
					color: '#fefefe',
				},
			}}
		/>
	</>
)
